import React from 'react';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  CardHeader,
} from "reactstrap";
const CycuIndex = (props) => {

  return (<div>
    <div className="section section-project cd-section" id="projects">
      <div className="features-1">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h2 className="title">最有趣的運算思維與程式設計</h2>
              <h5 className="description">
                歡迎來到陳胤辰的"運算思維與程式設計"<br />
                {/* 本課程授課18週，每週2小時<br /> */}
                本課談到許多有趣的思維方式，並且能實際的應用在生活中
              </h5>
            </Col>
          </Row>
          <Row>
            <Col md="3">
              <div className="info">
                <div className="icon icon-danger">
                  <i className="nc-icon nc-palette" />
                </div>
                <div className="description">
                  <h4 className="info-title">人文</h4>
                  {/* <p className="description"> */}
                  <p>
                    知古鑑今，知今識古，我們能更好的了解現在，並且能更好的預測未來
                  </p>
                  {/* <Button
                    className="btn-link"
                    color="danger"
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                  >
                    See more
                  </Button> */}
                </div>
              </div>
            </Col>
            <Col md="3">
              <div className="info">
                <div className="icon icon-danger">
                  <i className="nc-icon nc-bulb-63" />
                </div>
                <div className="description">
                  <h4 className="info-title">科技</h4>
                  <p>
                    擁有運算思維，掌握程式設計，你能夠更好的了解科技的發展
                  </p>
                  {/* <Button
                    className="btn-link"
                    color="danger"
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                  >
                    See more
                  </Button> */}
                </div>
              </div>
            </Col>
            <Col md="3">
              <div className="info">
                <div className="icon icon-danger">
                  <i className="nc-icon nc-chart-bar-32" />
                </div>
                <div className="description">
                  <h4 className="info-title">數據</h4>
                  <p>
                    日常生活中，我們都在使用數據，而數據的運用，就是運算思維的應用
                  </p>
                  {/* <Button
                    className="btn-link"
                    color="danger"
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                  >
                    See more
                  </Button> */}
                </div>
              </div>
            </Col>
            <Col md="3">
              <div className="info">
                <div className="icon icon-danger">
                  <i className="nc-icon nc-sun-fog-29" />
                </div>
                <div className="description">
                  <h4 className="info-title">創意</h4>
                  <p>
                    如何利用運算思維來創造出新的東西，有系統的解決不同的問題？
                  </p>
                  {/* <Button
                    className="btn-link"
                    color="danger"
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                  >
                    See more
                  </Button> */}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="project-4  section-dark">
        <br />
        <br />
        <br />
        <Container>
          <Row>
            <Col className="ml-auto" md="5">
              <Card className="card-plain">
                <CardBody>
                  <h6 className="card-category">Thinking</h6>
                  {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                  <CardTitle tag="h3">
                    不一樣的角度看世界<i className="fa fa-heart heart mr-3 ml-1" />
                  </CardTitle>
                  <CardTitle tag="h5">
                    <br />
                    用簡單的程式設計與演算法
                    <br />
                    理解生活實例中蘊含的運算思維
                  </CardTitle>
                  {/* </a> */}
                  <p className="card-description">
                    生活中的許多問題，其實都隱含著運算思維。而運算思維的能力，就是用數學與邏輯的方式來解決問題。在這堂課中，我們會用簡單的程式設計與演算法，來理解生活實例中蘊含的運算思維。
                  </p>
                  {/* <CardFooter>
                    <Button
                      className="btn-neutral mr-1"
                      color="link"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fa fa-book mr-1" />
                      Read More
                    </Button>
                    <Button
                      className="btn-just-icon btn-neutral mr-1"
                      color="link"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fa fa-apple" />
                    </Button>
                    <Button
                      className="btn-just-icon btn-neutral mr-1"
                      color="link"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fa fa-android" />
                    </Button>
                    <Button
                      className="btn-just-icon btn-neutral"
                      color="link"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fa fa-windows" />
                    </Button>
                  </CardFooter> */}
                </CardBody>
              </Card>
            </Col>
            <Col className="mr-auto" md="5">
              <Card
                data-background="image"
                style={{
                  backgroundImage:
                    "url(" +
                    // "/assets_front/img/sections/john-towner.jpg" +
                    "/assets_front/img/sections/sebastien-gabrieles.jpg" +
                    ")",
                }}
              >
                <div className="card-icon">
                  <i className="nc-icon nc-bulb-63" />
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <br />
      <div className="blog-3">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="10">
              {/* <h2 className="title">Latest Blogposts 3</h2> */}
              {/* <br /> */}
              <Card className="card-plain card-blog">
                <Row>
                  <Col md="4">
                    <div className="card-image">
                      <img
                        alt="..."
                        className="img"
                        src={"/assets_front/img/sections/jeff-sheldon.jpg"}
                      />
                    </div>
                  </Col>
                  <Col md="8">
                    <CardBody>
                      <h6 className="card-category text-info">
                        <i className="fa fa-solid fa-code mr-1" />
                        Programming</h6>
                      <CardTitle tag="h3">
                        程式設計
                      </CardTitle>
                      <p className="card-description">
                        從我們每天離不開的電腦、手機、平板電腦等裝置，到現在流行的ChatGPT、AI、元宇宙等科技潮流，這些都和程式設計息息相關。本堂課將看似複雜的程式邏輯提綱契領，讓非理工的學生也能一窺程式設計的魅力。
                      </p>
                      {/* <p className="author">
                        by{" "}
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <b>Megan Rose Dickey</b>
                        </a>
                        , 11 days ago
                      </p> */}
                    </CardBody>
                  </Col>
                </Row>
              </Card>
              <Card className="card-plain card-blog">
                <Row>
                  <Col md="8">
                    <CardBody>
                      <h6 className="card-category text-danger">
                        <i className="fa fa-solid fa-code-compare mr-1" />
                        Algorithm
                      </h6>
                      <CardTitle tag="h3">
                        演算法
                      </CardTitle>
                      <p className="card-description">
                        演算法是一系列有條理的步驟，能用於計算、解決問題、做出決定。而我們要如何將問題分解成一個個小問題，並且用程式來解決這些小問題，最後再將這些小問題的解答組合起來，就能解決原本的問題。
                      </p>
                      {/* <p className="author">
                        by{" "}
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <b>Josh Constine</b>
                        </a>
                        , 2 days ago
                      </p> */}
                    </CardBody>
                  </Col>
                  <Col md="4">
                    <div className="card-image">
                      <img
                        alt="..."
                        className="img"
                        src={"/assets_front/img/sections/rawpixel-comm.jpg"}
                      />
                    </div>
                  </Col>
                </Row>
              </Card>
              <Card className="card-plain card-blog">
                <Row>
                  <Col md="4">
                    <div className="card-image">
                      <img
                        alt="..."
                        className="img"
                        src={"/assets_front/img/sections/jeremy-yap.jpg"}
                      />
                    </div>
                  </Col>
                  <Col md="8">
                    <CardBody>
                      <h6 className="card-category text-success">
                        <i className="fa fa-regular fa-coffee mr-1" />
                        Living</h6>
                      <CardTitle tag="h3">
                        生活實例
                      </CardTitle>
                      <p className="card-description">
                        對這個世界的一些現象，有沒有想過它們是如何運作的？我們會用生活中的例子來解釋這些現象，透過分析、拆解，或背後隱含的數學原理，讓學生擁有多一個工具來理解這個有趣的世界。
                      </p>
                      {/* <p className="author">
                        by{" "}
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <b>Sarah Perez</b>
                        </a>
                        , 14 Jul 2020
                      </p> */}
                    </CardBody>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <div
        className="testimonials-1 section-image"
        style={{
          backgroundImage:
            'url(' + '/assets_front/img/sections/uriel-soberanes.jpg' + ')',
        }}
      >
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="6">
              <h2 className="title">學生分享</h2>
              <h5 className="description">
                本課專為非理工系學生設計，用有趣易理解的方式<br />輕鬆為您的大腦導入運算思維
              </h5>
            </Col>
          </Row>
          <div className="space-top" />
          <Row>
            <Col md="4">
              <Card className="card-testimonial">
                <div className="card-icon">
                  <i className="fa fa-quote-right" />
                </div>
                <CardBody>
                  <p className="card-description">
                    雖然一開始有滿滿的疑問，譬如說為什麼設計系還要學寫程式之類的，但這堂課讓我了解到，原來運算思維與程式設計和我們的科系息息相關
                  </p>
                  <CardFooter>
                    {/* <CardTitle tag="h4">中原商設一</CardTitle> */}
                    {/* <h6 className="card-category">@alecthompson</h6>
                    <div className="card-avatar">
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        <img
                          alt="..."
                          className="img"
                          src="/assets_front/img/faces/ayo-ogunseinde-2.jpg"
                        />
                      </a>
                    </div> */}
                  </CardFooter>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card className="card-testimonial">
                <div className="card-icon">
                  <i className="fa fa-quote-right" />
                </div>
                <CardBody>
                  <p className="card-description">
                    這堂和想像的不一樣，更加的有趣、面向也更廣，用小遊戲或有趣的故事代入，讓我們邏輯更加清楚，對程式也不陌生。老師講課語調很吸引人，也體諒我們這群沒什麼基礎的學生，非常感謝老師！
                  </p>
                  <CardFooter>
                    {/* <CardTitle tag="h4">中原商設一</CardTitle> */}
                    {/* <h6 className="card-category">@ginaandrew</h6>
                    <div className="card-avatar">
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        <img
                          alt="..."
                          className="img"
                          src="/assets_front/img/faces/joe-gardner-2.jpg"
                        />
                      </a>
                    </div> */}
                  </CardFooter>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card className="card-testimonial">
                <div className="card-icon">
                  <i className="fa fa-quote-right" />
                </div>
                <CardBody>
                  <p className="card-description">
                    國中上過C++，很不想上課覺得很困難，以為大學上的程式設計也會一樣，沒想到沒電腦，點名也是很新穎，頓時覺得很有趣
                  </p>
                  <CardFooter>
                    {/* <CardTitle tag="h4">中原商設一</CardTitle> */}
                    {/* <h6 className="card-category">@georgewest</h6> */}
                    {/* <div className="card-avatar">
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        <img
                          alt="..."
                          className="img"
                          src="/assets_front/img/faces/kaci-baum-2.jpg"
                        />
                      </a>
                    </div> */}
                  </CardFooter>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card className="card-testimonial">
                <div className="card-icon">
                  <i className="fa fa-quote-right" />
                </div>
                <CardBody>
                  <p className="card-description">
                    用水龍頭哪裡出現問題來教我們怎麼觀察和分析，上課也常分享很多生活的經驗給我們參考，讓我受益良多
                  </p>
                  <CardFooter>
                    {/* <CardTitle tag="h4">中原商設一</CardTitle> */}
                    {/* <h6 className="card-category">@georgewest</h6> */}
                    {/* <div className="card-avatar">
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        <img
                          alt="..."
                          className="img"
                          src="/assets_front/img/faces/kaci-baum-2.jpg"
                        />
                      </a>
                    </div> */}
                  </CardFooter>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card className="card-testimonial">
                <div className="card-icon">
                  <i className="fa fa-quote-right" />
                </div>
                <CardBody>
                  <p className="card-description">
                    還記得我是抱著非常忐忑的心情去上第一堂課的、但隨著課程的進行讓我不安的心情下降許多。課堂的氣氛是蠻輕鬆的，也反轉了可能需要用到強大數學能力的想法
                  </p>
                  <CardFooter>
                    {/* <CardTitle tag="h4">中原商設一</CardTitle> */}
                    {/* <h6 className="card-category">@georgewest</h6> */}
                    {/* <div className="card-avatar">
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        <img
                          alt="..."
                          className="img"
                          src="/assets_front/img/faces/kaci-baum-2.jpg"
                        />
                      </a>
                    </div> */}
                  </CardFooter>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card className="card-testimonial">
                <div className="card-icon">
                  <i className="fa fa-quote-right" />
                </div>
                <CardBody>
                  <p className="card-description">
                    我印象最深的是『條件判斷』高中的時候有學過，但還是有點燒腦，但老師在課堂上講得很仔細，搭配題目和工程師笑話，讓我更容易理解
                  </p>
                  <CardFooter>
                    {/* <CardTitle tag="h4">中原商設一</CardTitle> */}
                    {/* <h6 className="card-category">@georgewest</h6> */}
                    {/* <div className="card-avatar">
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        <img
                          alt="..."
                          className="img"
                          src="/assets_front/img/faces/kaci-baum-2.jpg"
                        />
                      </a>
                    </div> */}
                  </CardFooter>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* <div className="testimonials-3">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="6">
              <h2 className="title">最有幫助的地方</h2>
            </Col>
          </Row>
          <Row>
            <Col className="ml-auto" md="3">
              <Card data-background="color" data-color="orange">
                <CardBody>
                  <div className="author">
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      <img
                        alt="..."
                        className="avatar img-raised mr-2"
                        src="/assets_front/img/faces/joe-gardner-2.jpg"
                      />
                      <span>Erik Johnson</span>
                    </a>
                  </div>
                  <span className="category-social pull-right">
                    <i className="fa fa-quote-right" />
                  </span>
                  <div className="clearfix" />
                  <p className="card-description">
                    。
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card data-background="color" data-color="green">
                <CardBody>
                  <div className="author">
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      <img
                        alt="..."
                        className="avatar img-raised mr-2"
                        src="/assets_front/img/faces/erik-lucatero-2.jpg"
                      />
                      <span>Erik Johnson</span>
                    </a>
                  </div>
                  <span className="category-social pull-right">
                    <i className="fa fa-quote-right" />
                  </span>
                  <div className="clearfix" />
                  <p className="card-description">
                    用水龍頭哪裡出現問題來教我們怎麼觀察和分析，上課也常分享很多生活的經驗給我們參考，讓我受益良多
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col className="mr-auto" md="3">
              <Card data-background="color" data-color="yellow">
                <CardBody>
                  <div className="author">
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      <img
                        alt="..."
                        className="avatar img-raised mr-2"
                        src="/assets_front/img/faces/kaci-baum-2.jpg"
                      />
                      <span>Erik Johnson</span>
                    </a>
                  </div>
                  <span className="category-social pull-right">
                    <i className="fa fa-quote-right" />
                  </span>
                  <div className="clearfix" />
                  <p className="card-description">
                    "Simulation of many-body quantum systems with neural
                    networks, and the products are not burdened with
                    non-essentials. Back to purity, back to simplicity. At
                    best, it is self-explanatory."
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="ml-auto" md="4">
              <Card data-background="color" data-color="blue">
                <CardBody>
                  <div className="author">
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      <img
                        alt="..."
                        className="avatar img-raised mr-2"
                        src="/assets_front/img/faces/clem-onojeghuo-2.jpg"
                      />
                      <span>Erik Johnson</span>
                    </a>
                  </div>
                  <span className="category-social pull-right">
                    <i className="fa fa-quote-right" />
                  </span>
                  <div className="clearfix" />
                  <p className="card-description">
                    聽著教授高潮迭起的一生，真的超級有趣！心裡在想怎麼可以有人可以活的這麼精彩，而且真的就和電影裡面的男主角一樣，
                    在面臨一次次挑戰的時候都重拾自信重新出發。雖然我現在才大一但反而沒有像教授一樣對生活充滿熱情，
                    聽著教授的故事一邊羨慕一邊又在檢討自己的心態，想著哇⋯這就是我不能成功的原因嗎。
                    希望我之後也可以和教授一樣擁有一顆健康充滿活力與熱情的心去面對生活。
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col className="mr-auto" md="6">
              <Card data-background="color" data-color="purple">
                <CardBody>
                  <div className="author">
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      <img
                        alt="..."
                        className="avatar img-raised"
                        src="/assets_front/img/faces/ayo-ogunseinde-2.jpg"
                      />
                      <span>Erik Johnson</span>
                    </a>
                  </div>
                  <span className="category-social pull-right">
                    <i className="fa fa-quote-right" />
                  </span>
                  <div className="clearfix" />
                  <p className="card-description">
                    "Less, but better – because it concentrates on the
                    essential aspects, and the products are not burdened with
                    non-essentials. Back to purity, back to simplicity. At
                    best, it is self-explanatory. The entire AI Grant project
                    reminds me of a cross between a Thiel Fellowship and a
                    Kaggle competition."
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div> */}
      <div className="features-4">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h2 className="title">上課可以更有趣？</h2>
              <h5 className="description">
                傳統寫程式的方式，學生只能在老師的指導下，一步一步的寫出程式，但是這樣的方式學生很容易會因為一些小錯誤而放棄，
              </h5>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <div className="ipad-container">
                <img alt="..." src="/assets_front/img/sections/ipad.png" />
              </div>
            </Col>
            <Col className="offset-1" md="4">
              <div className="info info-horizontal">
                <div className="icon icon-info">
                  <i aria-hidden className="nc-icon nc-atom" />
                </div>
                <div className="description">
                  <h4 className="info-title">線上編程</h4>
                  <p>
                    為解決學生上課無趣的問題，我們提供線上編程的課程，讓學生可以在家中自由學習，並且可以即時與老師互動，讓學生在學習的過程中更有趣。
                  </p>
                </div>
              </div>
              <div className="info info-horizontal">
                <div className="icon icon-success">
                  <i aria-hidden className="nc-icon nc-ruler-pencil" />
                </div>
                <div className="description">
                  <h4 className="info-title">即時互動</h4>
                  <p>
                    如何透過LINE進行即時簽到、回答問卷、進行測驗？甚至還使用人臉辨識系統點名？讓學生在上課的時候不會玩手機，更能專心聽老師的課。
                  </p>
                </div>
              </div>
              <div className="info info-horizontal">
                <div className="icon icon-danger">
                  <i aria-hidden className="nc-icon nc-tie-bow" />
                </div>
                <div className="description">
                  <h4 className="info-title">生活案例</h4>
                  <p>
                    水管漏水、馬桶不通，甚至麻將怎麼打，樂透要不要買，都可以利用運算思維來解決？對，這就是我們的課程！
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* ********* CONTACT US 1 ********* */}
      <div
        className="contactus-1 section-image"
        style={{
          backgroundImage:
            'url(' + '/assets_front/img/sections/soroush-karimi.jpg' + ')',
        }}
      >
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="10">
              <Card className="card-contact no-transition">
                <CardTitle className="text-center" tag="h3">
                  與我連絡
                </CardTitle>
                <Row>
                  <Col className="ml-auto" md="5">
                    <CardBody>
                      <div className="info info-horizontal">
                        <div className="icon icon-info">
                          <i className="nc-icon nc-pin-3" />
                        </div>
                        <div className="description">
                          <h4 className="" style={{ color: '#333333' }}>
                            我的辦公室
                          </h4>
                          <p>
                            320 桃園市中壢區力行北街85號
                          </p>
                        </div>
                      </div>
                      <div className="info info-horizontal">
                        <div className="icon icon-danger">
                          <i className="nc-icon nc-badge" />
                        </div>
                        <div className="description">
                          <h4 className="" style={{ color: '#333333' }}>與我連絡</h4>
                          <p>
                            Michael Chen 陳胤辰<br />
                            Official Line: @371uiiye<br />
                            週一至週五 9:00 - 18:00
                          </p>
                        </div>
                      </div>
                    </CardBody>
                  </Col>
                  <Col className="mr-auto" md="5">
                    <Form id="contact-form" method="post" role="form">
                      <CardBody>
                        <Row>
                          <Col md="6">
                            <FormGroup className="label-floating">
                              <label className="control-label">
                                學號
                              </label>
                              <Input
                                name="number"
                                placeholder="學號"
                                type="text"
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup className="label-floating">
                              <label className="control-label">
                                姓名
                              </label>
                              <Input
                                name="name"
                                placeholder="姓名"
                                type="text"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <FormGroup className="label-floating">
                          <label className="control-label">
                            Email
                          </label>
                          <Input
                            name="email"
                            placeholder="Email"
                            type="email"
                          />
                        </FormGroup>
                        <FormGroup className="label-floating">
                          <label className="control-label">
                            您的問題或建議
                          </label>
                          <Input
                            id="message"
                            name="message"
                            placeholder="請輸入您的問題或建議，我將會與您聯絡。"
                            type="textarea"
                            rows="6"
                          />
                        </FormGroup>
                        <Row>
                          <Col md="6">
                            <FormGroup check>
                              <Label check>
                                <Input defaultValue="" type="checkbox" />
                                我不是機器人 !{' '}
                                <span className="form-check-sign" />
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <Button
                              className="pull-right"
                              color="primary"
                              type="submit"
                            >
                              送出
                            </Button>
                          </Col>
                        </Row>
                      </CardBody>
                    </Form>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* ********* END CONTACT US 1 ********* */}
    </div>
  </div>);
}

export default CycuIndex;
